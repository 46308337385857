import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrow, LazySvgoCalendar, LazySvgoCircle, LazySvgoCross, LazySvgoHamburger, LazySvgoLogo15Vertical, LazySvgoLogo15, LazySvgoLogoAjuntament, LazySvgoLogoDiputacio, LazySvgoLogoGeneralitat, LazySvgoLogoManifestaVertical, LazySvgoLogoManifesta, LazySvgoLogo, LazySvgoMap, LazySvgoMiniserioDeCulturaNegatiu, LazySvgoMinus, LazySvgoPlus, LazySvgoSearch, LazySvgoTriangleDown } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrow", LazySvgoArrow],
["SvgoCalendar", LazySvgoCalendar],
["SvgoCircle", LazySvgoCircle],
["SvgoCross", LazySvgoCross],
["SvgoHamburger", LazySvgoHamburger],
["SvgoLogo15Vertical", LazySvgoLogo15Vertical],
["SvgoLogo15", LazySvgoLogo15],
["SvgoLogoAjuntament", LazySvgoLogoAjuntament],
["SvgoLogoDiputacio", LazySvgoLogoDiputacio],
["SvgoLogoGeneralitat", LazySvgoLogoGeneralitat],
["SvgoLogoManifestaVertical", LazySvgoLogoManifestaVertical],
["SvgoLogoManifesta", LazySvgoLogoManifesta],
["SvgoLogo", LazySvgoLogo],
["SvgoMap", LazySvgoMap],
["SvgoMiniserioDeCulturaNegatiu", LazySvgoMiniserioDeCulturaNegatiu],
["SvgoMinus", LazySvgoMinus],
["SvgoPlus", LazySvgoPlus],
["SvgoSearch", LazySvgoSearch],
["SvgoTriangleDown", LazySvgoTriangleDown],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
