export default defineNuxtPlugin(async function () {
  if (typeof window !== "undefined") {
    const router = useRouter();

    var isScrollingTimeout;
    let isScrolling = false;

    window.addEventListener('scroll', function (event) {
      isScrolling = true
      window.clearTimeout(isScrollingTimeout);
      isScrollingTimeout = setTimeout(function () {
        isScrolling = false
      }, 66);
    }, false);

    router.beforeEach((to) => {
      if (to.hash) {
        if(to.hash?.includes('#')) { return }
        const targetElement = document?.querySelector(to.hash);
        console.log(targetElement)
        if (targetElement && !isScrolling) {
          const top = targetElement.getBoundingClientRect().top + window.scrollY
          window.scroll( { top: top, behavior: "instant" } )
        }
      }
    });
  }
});
